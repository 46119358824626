import React, { useContext } from 'react';
import {
  Navbar, Nav, NavDropdown, Container, Alert,
} from 'react-bootstrap';
import localStorage from 'local-storage';
import {
  faHome,
  faTachometerAlt,
  // faServer,
  faUsers,
  // faSearch,
  faMap,
  faUserClock,
  faStopwatch,
  faDonate,
  faBars,
  // faList,
  // faKeyboard,
  // faInfoCircle,
  // faUserLock,
  faSun,
  faMoon,
  faStar,
} from '@fortawesome/free-solid-svg-icons';
import {
  faDiscord,
  // faSteam,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ThemeToggler from '../../helpers/themeToggler';
import logo from '../../assets/images/clarity_fox.png';
import SearchPlayer from './searchPlayer';

function Header() {
  const [theme, setTheme] = useContext(ThemeToggler);

  const toggle = () => {
    if (localStorage.get('theme') === 'light') {
      setTheme('dark');
      localStorage.set('theme', 'dark');
    } else if (localStorage.get('theme') === 'dark') {
      setTheme('light');
      localStorage.set('theme', 'light');
    }
  };
  return (
    <div id="header" className={`navigation-${theme === 'dark' ? 'dark' : 'light'} sticky-top shadow`}>
      <Navbar bg="dark" expand="lg" variant={theme} sticky="top">
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="" className="img-fluid" width="50" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbar-slide offcanvas" />
          <Navbar.Collapse className="navbar-slide offcanvas" id="basic-navbar-nav">
            <Nav activeKey={window.location.pathname} className="mr-auto ml-auto">
              {/* <Nav.Link href="/">
                <FontAwesomeIcon icon={faHome} />
                {' '}
                Home
              </Nav.Link> */}
              <NavDropdown title={[<FontAwesomeIcon key={1} icon={faTachometerAlt} />, '  Dashboard']} id="dashboard-dropdown">
                <NavDropdown.Item href="/">
                  <FontAwesomeIcon icon={faHome} />
                  {' '}
                  Overview
                </NavDropdown.Item>
                {/* <NavDropdown.Item href="/dashboard/servers" disabled>
                  <FontAwesomeIcon icon={faServer} />
                  {' '}
                  Servers
                </NavDropdown.Item> */}
                {/* <NavDropdown.Divider />
                <NavDropdown.Item href="/dashboard/search-player" disabled>
                  <FontAwesomeIcon icon={faSearch} />
                  {' '}
                  Search Player
                </NavDropdown.Item> */}
                <NavDropdown.Divider />
                <NavDropdown.Item href="/dashboard/top-players">
                  <FontAwesomeIcon icon={faUsers} />
                  {' '}
                  Top Players
                </NavDropdown.Item>
                <NavDropdown.Item href="/dashboard/maps">
                  <FontAwesomeIcon icon={faMap} />
                  {' '}
                  Maps
                </NavDropdown.Item>
                <NavDropdown.Item href="/dashboard/most-active">
                  <FontAwesomeIcon icon={faUserClock} />
                  {' '}
                  Most Active
                </NavDropdown.Item>
                <NavDropdown.Item href="/dashboard/recent-records">
                  <FontAwesomeIcon icon={faStopwatch} />
                  {' '}
                  Recent Records
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/private" disabled>
                <FontAwesomeIcon icon={faStar} />
                {' '}
                Private Servers
              </Nav.Link>

              <Nav.Link href="/vip" disabled>
                <FontAwesomeIcon icon={faDonate} />
                {' '}
                Get VIP
              </Nav.Link>

              <Nav.Link href="https://discord.gg/WD7aSRx" target="_blank">
                <FontAwesomeIcon icon={faDiscord} />
                {' '}
                Discord
              </Nav.Link>

              {/* <Nav.Item onClick={() => toggle()}>
                {
                  theme === 'light'
                    ? [<FontAwesomeIcon key={3} icon={faMoon} />, ' Dark Theme']
                    : [<FontAwesomeIcon key={4} icon={faSun} />, ' Light Theme']
                }
              </Nav.Item> */}

              <NavDropdown key={2} title={[<FontAwesomeIcon key={2} icon={faBars} />, ' Other']} id="other-dropdown">
                {/* <NavDropdown.Item href="https://discord.gg/WD7aSRx" target="_blank">
                  <FontAwesomeIcon icon={faDiscord} />
                  {' '}
                  Discord
                </NavDropdown.Item>
                <NavDropdown.Item href="/steam-group" disabled>
                  <FontAwesomeIcon icon={faSteam} />
                  {' '}
                  Steam Group
                </NavDropdown.Item>
                <NavDropdown.Header>In Game</NavDropdown.Header>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/dashboard/rules" disabled>
                  <FontAwesomeIcon icon={faList} />
                  {' '}
                  Rules
                </NavDropdown.Item>
                <NavDropdown.Item href="/dashboard/commands" disabled>
                  <FontAwesomeIcon icon={faKeyboard} />
                  {' '}
                  Commands
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/dashboard/server-status" disabled>
                  <FontAwesomeIcon icon={faServer} />
                  {' '}
                  Server Status
                </NavDropdown.Item> */}
                <NavDropdown.Item onClick={() => toggle()}>
                  {
                    theme === 'light'
                      ? [<FontAwesomeIcon key={3} icon={faMoon} />, ' Dark Theme']
                      : [<FontAwesomeIcon key={4} icon={faSun} />, ' Light Theme']
                  }
                </NavDropdown.Item>
                {/* <NavDropdown.Divider />
                <NavDropdown.Item href="/dashboard/terms-of-service" disabled>
                  <FontAwesomeIcon icon={faInfoCircle} />
                  {' '}
                  Terms of Service
                </NavDropdown.Item>
                <NavDropdown.Item href="/dashboard/privacy-policy" disabled>
                  <FontAwesomeIcon icon={faUserLock} />
                  {' '}
                  Privacy Policy
                </NavDropdown.Item> */}
              </NavDropdown>
            </Nav>
            <SearchPlayer theme={theme} />
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className={`${theme}`}>
        <Alert className={`${theme === 'light' ? 'text-dark' : ''} text-center alert-info`}>
          <span className="btn-link-info">
            64 Tick Rankings Only
          </span>
        </Alert>
      </div>
    </div>

  );
}

export default Header;
